import axios from 'axios';
import store from '@/store';
import { Toast } from 'vant';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000,
    validateStatus: function(status) {
        return true; // default
    }
});

service.interceptors.request.use(config => {
    const token = store.state.token;
    console.log(token);
    if (token) {
        if (config.params) {
            config.params = {
                ...config.params,
                token
            };
        } else {
            config.params = {
                token
            };
        }
    }
    return config;
});

service.interceptors.response.use(response => {
    const data = response.data;
    const status = response.status;
    switch (status) {
        case 401:
            Toast.fail({
                message: '登录状态过期'
            });
            break;
        case 403:
            break;
        case 404:
            Toast.fail({
                message: '路径错误'
            });
            break;
        case 500:
        case 502:
            Toast.fail({
                message: '服务器异常'
            });
            break;
    }
    switch (data.code) {
        case '000':
        case '500':
        case '401':
        case '005':
        case '006':
        case '007':
            //调用失败
            Toast.fail({
                message: data.msg
            });
            break;
        case '999':
            break;
        default:
            Toast.fail({
                message: data.msg
            });
    }
    return response.data;
});

export default service;
